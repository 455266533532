import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { EventBus } from "@/core/event-bus/event.bus";
import { QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";
export default {
  name: "KTMenu",
  data: function data() {
    return {
      loading: false,
      used_storage: null,
      allocated_storage: null,
      used_percentage: 0
    };
  },
  methods: {
    upload_dialog: function upload_dialog(param) {
      if (this.canUpload) {
        EventBus.$emit("upload-dialog", param);
      }
    },
    hasActiveChildren: function hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    init_storage: function init_storage() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(QUERY, {
        url: "account/storage?t=".concat(new Date().getTime())
      }).then(function (response) {
        _this.used_storage = ObjectPath.get(response, "used-storage-formatted", null);
        _this.allocated_storage = ObjectPath.get(response, "allocated-storage-formatted", null);
        _this.used_percentage = ObjectPath.get(response, "used-percentage", 0);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off("refresh-storage");
  },
  computed: _objectSpread({}, mapGetters(["canUpload", "isUploadDisabled"])),
  mounted: function mounted() {
    var _this2 = this;

    this.init_storage();
    EventBus.$on("refresh-storage", function () {
      _this2.init_storage();
    });
  }
};