import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { toNumber, round, findIndex, toSafeInteger, uniq, sum } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TagAutoComplete from "@/view/components/TagAutoComplete";
import SearchMembers from "@/view/components/SearchMembers";
import { GET, QUERY, UPLOAD } from "@/core/services/store/request.module";
import { ERROR } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import ObjectPath from "object-path";
import { AES, enc } from "crypto-js";
export default {
  name: "KTUPLOAD",
  components: {
    Dialog: Dialog,
    TextInput: TextInput,
    DatePicker: DatePicker,
    TagAutoComplete: TagAutoComplete,
    AutoCompleteInput: AutoCompleteInput,
    "search-members": SearchMembers
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      form_valid: true,
      file_loading: false,
      reminder_type_items: [],
      files: [],
      folders: [],
      upload: {
        reference: null,
        tags: [],
        raw_tags: [],
        start_date: null,
        expiry_date: null,
        is_public: 0,
        share_with: [],
        set_reminder: 0,
        reminder: {
          type: 1,
          start_date: null,
          frequency: null,
          end_date: null,
          total: null
        }
      },
      default_member: {
        id: 0,
        permission: null,
        title: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        display_name: null,
        email: null,
        profile: null
      },
      timeout: null,
      timeout_limit: 500
    };
  },
  methods: {
    update_tags: function update_tags() {
      this.upload.tags = this.upload.raw_tags;
    },
    get_reminder_values: function get_reminder_values() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "upload/reminder-values",
          data: _this.upload.reminder
        }).then(function (response) {
          _this.upload.reminder.end_date = response.last_date;
          _this.upload.reminder.total = response.total;
        }).catch(function (error) {
          console.log({
            error: error
          });
        });
      }, _this.timeout_limit);
    },
    delete_member: function delete_member(member) {
      var index = findIndex(this.upload.share_with, {
        id: member.id
      });
      this.upload.share_with.splice(index, 1);
    },
    get_options: function get_options() {
      var _this2 = this;

      this.$store.dispatch(GET, {
        url: "upload/options?is-folder=1"
      }).then(function (response) {
        _this2.upload.reference = response.barcode;
        _this2.default_member = response.default_member;
        _this2.reminder_type_items = response.reminder_type_items;
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {// console.log({param})
      });
    },
    update_files: function update_files(param) {
      if (param) {
        var files = [];
        var folders = [];
        var total_size = [];
        var max_size = toNumber(this.$uploadSizeLIMIT);
        var max_file = toNumber(this.$uploadLIMIT);

        for (var i = 0; i < param.length; i++) {
          var size = round(toNumber(param[i].size / 1024), 2);

          if (size <= max_size && this.files.length < max_file) {
            var webkitRelativePath = param[i].webkitRelativePath;
            var folder = webkitRelativePath.split("/")[0];
            total_size.push(param[i].size);
            folders.push(folder);
            files.push(param[i]);
          }
        }

        this.files = files;
        this.total_bytes = sum(total_size);
        this.folders = uniq(folders);
      }
    },
    get_file_size: function get_file_size(size) {
      return round(toNumber(size) / 1024, 2);
    },
    remove_folder: function remove_folder() {
      this.files = [];
      this.total_bytes = 0;
      this.folders = [];
    },
    max_file_size: function max_file_size() {
      return this.humanFileSize(toNumber(this.$uploadSizeLIMIT) * 1024, true);
    },
    select_file: function select_file() {
      var _this3 = this;

      this.$nextTick(function () {
        _this3.$refs["upload-file"].reset();

        _this3.$refs["upload-file"].$refs["input"].click();
      });
    },
    submit_file: function submit_file() {
      try {
        var _this = this;

        _this.$refs.uploadFolderForm.validate();

        if (!_this.files.length) {
          _this.$store.dispatch(ERROR, [{
            model: true,
            message: "Please select atleast one file"
          }]);

          return false;
        }

        var formErrors = _this.validateForm(_this.$refs.uploadFolderForm);

        if (formErrors.length) {
          _this.$store.dispatch(ERROR, formErrors);

          return false;
        }

        if (!_this.$refs.uploadFolderForm.validate()) {
          return false;
        }

        var form_data = new FormData();

        for (var i = 0; i < _this.files.length; i++) {
          form_data.append("folders[".concat(i, "][file]"), _this.files[i], _this.files[i].name);
          form_data.append("folders[".concat(i, "][path]"), _this.files[i].webkitRelativePath);
        }

        for (var z = 0; z < _this.upload.tags.length; z++) {
          form_data.append("tags[".concat(z, "][text]"), _this.upload.tags[z].text);
          form_data.append("tags[".concat(z, "][color]"), _this.upload.tags[z].color);
        }

        for (var x = 0; x < _this.upload.share_with.length; x++) {
          form_data.append("share_with[".concat(x, "][id]"), _this.upload.share_with[x].id);
          form_data.append("share_with[".concat(x, "][permission]"), _this.upload.share_with[x].permission);
        }

        form_data.append("reference", _this.upload.reference);
        var folder = ObjectPath.get(_this.$route, "query.folder");

        if (folder) {
          var decrypted = AES.decrypt(folder, _this.$enc_key);
          var linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));

          if (linked_id) {
            form_data.append("linked_id", linked_id);
          }
        }

        if (_this.upload.start_date) {
          form_data.append("start_date", _this.upload.start_date);
        }

        if (_this.upload.expiry_date) {
          form_data.append("expiry_date", _this.upload.expiry_date);
        }

        form_data.append("is_public", _this.upload.is_public);
        form_data.append("set_reminder", _this.upload.set_reminder);

        if (_this.upload.set_reminder == 1) {
          form_data.append("reminder[type]", _this.upload.reminder.type);

          if (_this.upload.reminder.start_date) {
            form_data.append("reminder[start_date]", _this.upload.reminder.start_date);
          }

          if (_this.upload.reminder.frequency) {
            form_data.append("reminder[frequency]", _this.upload.reminder.frequency);
          }

          if (_this.upload.reminder.end_date) {
            form_data.append("reminder[end_date]", _this.upload.reminder.end_date);
          }
        }

        _this.file_loading = true;

        _this.$store.dispatch(UPLOAD, {
          url: "folders",
          data: form_data
        }).then(function () {
          _this.$emit("success", true);

          EventBus.$emit("refresh-listing", true);
          EventBus.$emit("refresh-storage", true);
        }).catch(function (error) {
          console.log({
            error: error
          });
        }).finally(function () {
          _this.file_loading = false;
        });
      } catch (error) {
        console.log({
          error: error
        });
      }
    }
  },
  mounted: function mounted() {
    this.get_options();
  }
};