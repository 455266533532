import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { ERROR } from "@/core/services/store/common.module";
import UploadTemplate from "@/view/components/UploadTemplate";
import FolderUploadTemplate from "@/view/components/FolderUploadTemplate";
import FolderNewTemplate from "@/view/components/FolderNewTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { GET } from "@/core/services/store/request.module";
import { find, findIndex } from "lodash";
import ObjectPath from "object-path";
export default {
  name: "Layout",
  components: {
    KTAside: KTAside,
    KTHeader: KTHeader,
    KTHeaderMobile: KTHeaderMobile,
    KTScrollTop: KTScrollTop,
    Loader: Loader,
    FolderUploadTemplate: FolderUploadTemplate,
    UploadTemplate: UploadTemplate,
    FolderNewTemplate: FolderNewTemplate
  },
  data: function data() {
    return {
      upload_dialog: false,
      folder_upload_dialog: false,
      folder_new_dialog: false,
      download_box: 0,
      fdownloads: []
    };
  },
  beforeMount: function beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading"); // initialize html element classes

    HtmlClass.init(this.layoutConfig());
  },
  methods: {
    get_download_title: function get_download_title() {
      var has_false = find(this.fdownloads, {
        status: false
      });

      if (has_false) {
        return "Preparing Download";
      }

      return "Download Ready";
    },
    download_file: function download_file(row) {
      if (row.status && row.url) {
        window.open(row.url, "_blank");
      }
    },
    delete_row: function delete_row(index) {
      this.fdownloads.splice(index, 1);
    },
    bind_pusher: function bind_pusher(uuid, name) {
      var _this = this;

      _this.download_box = 0;

      _this.fdownloads.push({
        status: false,
        url: null,
        name: name,
        uuid: uuid
      });

      _this.$pusher.bind("download-".concat(uuid), function (row) {
        var puuid = ObjectPath.get(row, "uuid", null);
        var purl = ObjectPath.get(row, "url", null);
        var pname = ObjectPath.get(row, "name", null);
        var index = findIndex(_this.fdownloads, {
          uuid: puuid
        });

        if (index >= 0) {
          _this.fdownloads[index].name = pname;
          _this.fdownloads[index].url = purl;
          _this.fdownloads[index].status = true;
        }
      });
    },
    get_profile: function get_profile() {
      var _this2 = this;

      this.$store.dispatch(GET, {
        url: "users/profile"
      }).then(function (response) {
        _this2.$store.dispatch(UPDATE_PERSONAL_INFO, response);
      }).catch(function (error) {
        console.log({
          error: error
        });
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({
        name: "login"
      });
    }

    this.get_profile();
    EventBus.$on("init-download", function (_ref) {
      var uuid = _ref.uuid,
          name = _ref.name;

      _this3.bind_pusher(uuid, name);
    });
    ErrorEventBus.$on("set-error", function (param) {
      _this3.$store.dispatch(ERROR, param);
    });
    EventBus.$on("upload-file", function (param) {
      _this3.upload_dialog = param;
    });
    EventBus.$on("upload-folder", function (param) {
      _this3.folder_upload_dialog = param;
    });
    EventBus.$on("create-folder", function (param) {
      _this3.folder_new_dialog = param;
    });
    EventBus.$on("upload-dialog", function (param) {
      if (param == "new-folder") {
        _this3.folder_new_dialog = true;
      }

      if (param == "file-upload") {
        _this3.upload_dialog = true;
      }

      if (param == "folder-upload") {
        _this3.folder_upload_dialog = true;
      }
    }); // Simulate the delay page loading

    setTimeout(function () {
      // Remove page loader after some time
      _this3.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  watch: {
    isAuthenticated: function isAuthenticated(param) {
      if (!param) {
        this.$router.push({
          name: "login"
        });
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off("init-download");
    EventBus.$off("upload-file");
    EventBus.$off("upload-folder");
    EventBus.$off("create-folder");
    EventBus.$off("upload-dialog");
    ErrorEventBus.$off("set-error");
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["isAuthenticated", "errors", "messages", "layoutConfig"])), {}, {
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled: function loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid: function contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo: function loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled: function asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  })
};